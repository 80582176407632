export const APRIL = 3
export const MAY = 4
export const JUNE = 5
export const JULY = 6
export const AUG = 7
export const SEPT = 8
export const OCT = 9

export const REGISTRATION_OPENS = 'April 1'
export const CAMP_REGISTRATION_OPENS = 'April 1'

export const LADDERS_START = 'Mid-April'

export const JUNIOR_LESSONS_START = 'May 14'
export const JUNIOR_LESSONS_END = 'June 20'

export const JUNIOR_CAMPS_START = 'July 2'
export const JUNIOR_CAMPS_END = 'Aug 30'

export const ADULT_DOUBLES_ASSESSMENT = 'May (TBD)'
export const ADULT_DOUBLES_START = 'May 12'
export const ADULT_DOUBLES_END = 'Sept 29'
export const ADULT_DOUBLES_PRICE = 20

export const ADULT_LESSONS_START = 'May 15'

export const SINGLES_DROPIN_START = 'May 7'
export const SINGLES_DROPIN_END = 'Oct 1'

export const SEASON_OPENING_FESTIVAL_DATE = 'June 1'
export const PARENT_CHILD_TOURN_DATE = 'June 22'
export const WIMBLEDON_DATE = 'July 13'
export const ICECREAM_TOUR_DATE = 'July 27'
export const JUNIOR_FRENCH_OPEN_DATE = 'May 18'

export const CLUB_CHAMPIONSHIPS_START = 'Aug 1'
export const CLUB_CHAMPIONSHIPS_FINALS_SINGLES_DATE = 'Sept 14-15'
export const CLUB_CHAMPIONSHIPS_FINALS_DOUBLES_DATE = 'Sept 21-22'

export const JUNIOR_CLUB_CHAMPIONSHIPS = 'Sept 7-8'

export const INTERURBAN_TRYOUTS = 'April 25'
export const INTERURBAN_TRYOUTS_TIME = '7-9pm'

import { Button, Col, Divider, Row, Typography } from 'antd'

// import DOUBLES_BEGINNERS from '../../assets/docs/championships-2023/beginners-doubles.pdf'
// import DOUBLES_LADIES from '../../assets/docs/championships-2023/ladies-doubles.pdf'
// import DOUBLES_MENS from '../../assets/docs/championships-2023/mens-doubles.pdf'
// import DOUBLES_MIXED from '../../assets/docs/championships-2023/mixed-doubles.pdf'
// import SINGLES_OPEN from '../../assets/docs/championships-2023/open-singles.pdf'
import { Link } from 'react-router-dom'
import RULES from '../../assets/docs/championships-2023/rules.pdf'
import Section from '../common/Section'

const { Paragraph, Text } = Typography

const SINGLES_OPEN_URL = 'https://drive.google.com/file/d/19rLpLBgXRiseWcnQh7zXjqE9yKgwje5X/view?usp=sharing'
const SINGLES_LADIES = 'https://drive.google.com/file/d/1pWzwe_JP8qo6HcuqS49P3-M0pWOrj63t/view?usp=sharing'
const DOUBLES_MIXED_URL = 'https://drive.google.com/file/d/1ABOmTFktOr1ws3ZCd3viwdT0e7AYF0ND/view?usp=sharing'
const DOUBLES_OPEN_URL = 'https://drive.google.com/file/d/1NeueHMldiYFmSBVHDrHXchd8-MmwJMgc/view?usp=sharing'
const DOUBLES_LADIES_URL = 'https://drive.google.com/file/d/1QA0Ax7vtk8OqzhkMwqEve7KrjGUscq8b/view?usp=sharing'
const DOUBLES_BEGINNERS_URL = 'https://drive.google.com/file/d/1zRlhX-RwVV9GSYqpJLpwM_bz9iGgxiHq/view?usp=sharing'

const PLAYOFF_SINGLES_OPEN_URL = 'https://drive.google.com/file/d/1SfI4sliJ3JRlKopvLadMNdutzSgn5YFE/view?usp=sharing'
const PLAYOFF_DOUBLES_MIXED_URL = 'https://drive.google.com/file/d/12RxPo6WT49NeKI8hakBnTzKT6QAox0NW/view?usp=sharing'
const PLAYOFF_DOUBLES_MENS_URL = 'https://drive.google.com/file/d/1NPidtWFLgQJeI4Prv-2O0yykelFXryhs/view?usp=sharing'
const PLAYOFF_DOUBLES_LADIES_URL = 'https://drive.google.com/file/d/1vYtTbvSLxZd-tOkbSGiNPxd2vb4J96zk/view?usp=sharing'
const PLAYOFF_DOUBLES_BEGINNERS_URL =
  'https://drive.google.com/file/d/1uup5-c_ab0YuAp_kX1enzGNVvsGPrq3j/view?usp=sharing'
const PLAYOFF_SINGLES_LADIES_URL = 'https://drive.google.com/file/d/1AEG43lXqnZifOtKXJAjuuQL1JrA3XZTO/view?usp=sharing'

const ClubChampionshipsSection = () => {
  const ResultsButton = ({ label, url, ...props }) => (
    <Button className="padRight" type="link" target="_blank" href={url} {...props}>
      {label}
    </Button>
  )
  return (
    <>
      <Row
        gutter={30}
        justify="space-between"
        style={{
          paddingTop: '1em',
          backgroundColor: '#feffe8',
          border: '1px solid #ccc',
        }}
      >
        <Col flex="1">
          <Paragraph strong style={{ fontSize: '1.2em' }}>
            Adult Club Championships
          </Paragraph>
          {/* <Paragraph>
            Sign up for the adult club championships now! Signups close <b>August 3rd, 11:59pm</b>
          </Paragraph> */}
          <Paragraph>
            {/* <Button
              // className="noPad"
              type="default"
              target="_blank"
              href="https://docs.google.com/forms/d/1ipuTGifNCjnBcIMAaWxPzRZuykWZ0Q4eV2mL7NAu5Yg"
            >
              Sign up now
            </Button> */}
            {/* Signups are now closed. The draws will be emailed to participants and posted here soon. */}
          </Paragraph>
          {/* <Paragraph>
            Our club championship divisions include:
            <ul style={{ paddingTop: 0 }}>
              <li>Open singles (men and ladies welcome)</li>
              <li>Ladies' singles</li>
              <li>Open doubles (men's and ladies' teams welcome)</li>
              <li>Ladies' doubles</li>
              <li>Mixed doubles</li>
              <li>Beginner doubles</li>
            </ul>
          </Paragraph> */}

          {/* <Paragraph>The round robin matches have completed and playoff brackets are set!</Paragraph>
          <Paragraph>
            <ul style={{ paddingTop: 0 }}>
              <li>
                Singles playoffs: <ResultsButton label="Open singles" url={PLAYOFF_SINGLES_OPEN_URL} />
                <ResultsButton label="Ladies singles" url={PLAYOFF_SINGLES_LADIES_URL} />
              </li>
              <li>
                Doubles playoffs:
                <ResultsButton label="Beginners doubles" url={PLAYOFF_DOUBLES_BEGINNERS_URL} />
                <ResultsButton label="Mixed doubles" url={PLAYOFF_DOUBLES_MIXED_URL} />
                <ResultsButton label="Ladies doubles" url={PLAYOFF_DOUBLES_LADIES_URL} />
                <ResultsButton label="Mens doubles" url={PLAYOFF_DOUBLES_MENS_URL} />
              </li>
            </ul>
          </Paragraph>
          <Divider />
*/}
          <Paragraph>
            Round robin divisions: <ResultsButton label="Open singles" url={SINGLES_OPEN_URL} />
            <ResultsButton label="Ladies singles" url={SINGLES_LADIES} />
            <ResultsButton label="Beginners doubles" url={DOUBLES_BEGINNERS_URL} />
            <ResultsButton label="Mixed doubles" url={DOUBLES_MIXED_URL} />
            <ResultsButton label="Ladies doubles" url={DOUBLES_LADIES_URL} />
            <ResultsButton label="Open doubles" url={DOUBLES_OPEN_URL} />
          </Paragraph>
          {/* <Paragraph className="note">
            Many thanks to our Club Championships tournament director Nathan Hellinga for managing the tournament again
            this year!
          </Paragraph> */}
        </Col>
      </Row>
    </>
  )
}
export default ClubChampionshipsSection
